<template>
  <div class="container">
    <ClassTypeDialog
      :defaultIsOnline="isOnline"
      :visible="classTypeDialogVisible"
      @submit="
        newIsOnline => {
          classTypeDialogVisible = false;
          isOnline = newIsOnline;
        }
      "
      @toggleDialogVisible="visible => (classTypeDialogVisible = visible)"
    />
    <el-dialog
      title="此次信件寄送對象資訊"
      :visible.sync="sendMailInformationVidible"
      width="600px"
    >
      <el-table :data="teacherMailInfo">
        <el-table-column
          align="center"
          label="Email the student and student's parent"
        >
          <template slot-scope="scope">
            <el-checkbox
              :true-label="1"
              :false-label="0"
              :value="scope.row.sendMail.sendToStudents"
              @input="
                value =>
                  (privateLesson = {
                    ...privateLesson,
                    sendToParents: value,
                    sendToStudents: value
                  })
              "
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="Email the teacher">
          <template slot-scope="scope">
            <el-checkbox
              :true-label="1"
              :false-label="0"
              :value="scope.row.sendMail.sendToTeacher"
              @input="
                value =>
                  (privateLesson = { ...privateLesson, sendToTeacher: value })
              "
            />
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sendMailInformationVidible = false">
          {{ $t("button.cancel") }}
        </el-button>
        <el-button type="primary" @click="onSubmit">
          {{ $t("button.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('message.notice')"
      :visible.sync="ifDoYouWantToContinueDialogVisible"
      width="600px"
    >
      <span>{{ $t("message.thereAreOtherIncompleteLessonLog") }}</span>
      <el-table :data="getAfterNotYetFinishedLessonLogs()">
        <el-table-column :label="$t('column.date')">
          <template slot-scope="scope">
            {{ scope.row.teaching_log.started_at.split(" ")[0] }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.action')">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'privateClassesEditLog',
                params: {
                  privateType: $route.params.privateType,
                  id: $route.params.id,
                  lessonLogId: scope.row.id
                }
              }"
            >
              <i class="fas fa-edit action-icon" />
            </router-link>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifDoYouWantToContinueDialogVisible = false">
          {{ $t("message.noContinueCreateANewLessonLog") }}
        </el-button>
      </span>
    </el-dialog>
    <Breadcrumb>
      <template slot="restPage">
        <!-- <el-breadcrumb-item>
          <router-link :to="`/private/${$route.params.privateType}`">
            {{
              `${
                isCounseling
                  ? $t("pageTitle.counseling")
                  : $t("pageTitle.privateLessons")
              }`
            }}
          </router-link>
        </el-breadcrumb-item> -->
        <el-breadcrumb-item>
          <router-link :to="{ name: 'myClasses' }">
            {{ $t("pageTitle.myClasses") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'ClassLessonLogs',
              params: { classId: $route.params.id }
            }"
          >
            <IsOnlineIcon :isOnline="isOnline" />
            {{ subject }}
          </router-link>
        </el-breadcrumb-item>
        <!-- <el-breadcrumb-item v-else>
          <router-link
            :to="{ name: 'classDetail', params: { classId: $route.params.id } }"
          >
            <IsOnlineIcon :isOnline="isOnline" />
            {{ subject }}
          </router-link>
        </el-breadcrumb-item> -->
        <el-breadcrumb-item>
          {{ isEditModel ? "Edit" : "Add" }} {{ `${logType} Log` }} -
          <IsOnlineIcon :isOnline="isOnline" />
          {{ subject }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-divider />
    <div>
      <h1>
        {{ isEditModel ? "Edit" : "Add" }} {{ `${logType} Log` }} -
        <IsOnlineIcon :isOnline="isOnline" />
        {{ this.subject }}
      </h1>
      <p>
        Please log your lesson here, preferably right after the lesson but
        definitely within 24 hours after your lesson. This not only ensures you
        get paid on time, but also ensures the student gets a timely homework
        reminder email, and the parent gets a timely mini progress report about
        this lesson.
        <!-- (Click here to log a counseling meeting instead of a
        tutoring lesson) -->
      </p>
    </div>
    <div>
      <el-form
        ref="form"
        :model="privateLesson"
        @submit.prevent.native="submit"
        :validate-on-rule-change="false"
        label-position="right"
        label-width="120px"
        v-if="privateLesson.teachers.length > 0"
      >
        <el-form-item
          label="Log Type:"
          v-if="privateLesson.teachers[0].is_active"
        >
          <el-radio-group
            v-if="privateLesson.teachers[0].is_active === 1"
            v-model="type"
          >
            <el-radio-button label="LS">Class</el-radio-button>
            <el-radio-button label="MG">Meet and Greet/Trial</el-radio-button>
          </el-radio-group>
          <el-radio-group
            v-else-if="privateLesson.teachers[0].is_active === 2"
            v-model="type"
          >
            <el-radio label="MG">Meet and Greet/Trial</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Class type:">
          <el-radio-group
            :value="isOnline"
            @input="is_online => (isOnline = is_online)"
          >
            <el-radio-button :label="1">Online</el-radio-button>
            <el-radio-button :label="0">In-person</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Teacher:">
          <div>
            <el-table :data="privateLesson.teachers" style="width: 100%">
              <el-table-column width="100" label="No" prop="no" />
              <el-table-column label="Teacher">
                <template slot-scope="scope">
                  <div v-if="!isEditModel && isRoleAdmin()">
                    <el-select
                      :value="currentMeetingTeacherId"
                      @change="setCurrentMeetingTeacher"
                    >
                      <el-option
                        :key="teacher.teacher_user_id"
                        v-for="teacher in originalCustomLesson.session_class
                          .session_class_teachers"
                        :label="teacher.teacher_user.name"
                        :value="teacher.teacher_user_id"
                      />
                    </el-select>
                    <router-link
                      :to="{
                        name: 'ProfileForAdmin',
                        params: { id: scope.row.teacherUserId }
                      }"
                    >
                      <i class="fa fa-eye action-icon" />
                    </router-link>
                  </div>
                  <div v-else>
                    <router-link
                      :to="{
                        name: 'ProfileForAdmin',
                        params: { id: scope.row.teacherUserId }
                      }"
                    >
                      {{ scope.row.name }}
                    </router-link>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="isPrivateLesson"
                width="400"
                label="Salary"
              >
                <template slot-scope="scope">
                  <el-row
                    v-if="isRoleAdmin()"
                    :gutter="20"
                    type="flex"
                    align="middle"
                  >
                    <el-col :span="13">
                      <InputMoney
                        :money="scope.row.salary"
                        :currency="scope.row.salaryCurrency"
                        @changeMoney="
                          salary => {
                            setTeacher({ salary });
                          }
                        "
                        @changeCurrency="
                          currency => {
                            setTeacher({ salaryCurrency: currency });
                          }
                        "
                      />
                    </el-col>
                    <el-col :span="3">
                      /hr
                    </el-col>
                  </el-row>
                  <div v-else>
                    {{
                      addCurrencySymbol(
                        scope.row.salary,
                        scope.row.salaryCurrency
                      )
                    }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="Student:">
          <el-table :data="privateLesson.students" style="width: 100%">
            <el-table-column width="100" label="No" prop="no" />
            <el-table-column label="Student" prop="name">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.parents == null"
                  style="text-decoration: line-through;"
                >
                  {{ scope.row.name }}
                </span>
                <b v-else>
                  {{ scope.row.name }}
                </b>
              </template>
            </el-table-column>
            <el-table-column width="56">
              <template slot-scope="scope">
                <AttendanceStatusLight
                  :attendanceStatus="scope.row.attendanceStatus"
                />
              </template>
            </el-table-column>
            <el-table-column width="300" label="Attendance">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="12">
                    <el-select
                      filterable
                      default-first-option
                      :value="scope.row.attendanceStatus"
                      :filter-method="filterAttendanceStatus"
                      @change="
                        attendanceStatus => {
                          setStudent(scope.row.studentClassTicketId, {
                            attendanceStatus
                          });
                        }
                      "
                    >
                      <el-option
                        :key="showAttendanceStatusOption.label"
                        v-for="showAttendanceStatusOption in showAttendanceStatusOptions"
                        :label="showAttendanceStatusOption.label"
                        :value="showAttendanceStatusOption.value"
                      />
                    </el-select>
                  </el-col>
                  <el-col :span="12">
                    <el-select
                      v-if="
                        scope.row.attendanceStatus ===
                          EnumAttendanceStatuses.Excused
                      "
                      filterable
                      allow-create
                      default-first-option
                      placeholder="Reason"
                      :value="scope.row.absenceExcuse"
                      @change="
                        absenceExcuse => {
                          setStudent(scope.row.studentClassTicketId, {
                            absenceExcuse
                          });
                        }
                      "
                    >
                      <el-option
                        v-for="item in [
                          { label: 'Sick' },
                          { label: 'Personal' },
                          { label: 'Other' }
                        ]"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              label="Tuition"
              width="350"
              v-if="isPrivateLesson && isRoleAdmin()"
            >
              <template slot-scope="scope">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="15">
                    <InputMoney
                      :money="scope.row.fee"
                      :currency="scope.row.feeCurrency"
                      @changeMoney="
                        fee => {
                          setStudent(scope.row.studentClassTicketId, { fee });
                        }
                      "
                      @changeCurrency="
                        currency => {
                          setStudent(scope.row.studentClassTicketId, {
                            feeCurrency: currency
                          });
                        }
                      "
                    />
                  </el-col>
                  <el-col :span="3">
                    /hr
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="收信家長" prop="name" width="300">
              <template slot-scope="scope">
                <el-checkbox-group v-model="scope.row.parents_receivers">
                  <el-checkbox
                    v-for="(parent, index) in scope.row.parents
                      ? scope.row.parents
                      : []"
                    :label="parent.id"
                    :key="parent.id"
                  >
                    {{
                      `${userMethods.displayName(
                        parent.first_name,
                        parent.last_name
                      )} (${parent.email})`
                    }}
                  </el-checkbox>
                </el-checkbox-group>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item :label="`${logType} time:`">
          <el-row>
            <el-col :span="12">
              <el-date-picker
                value-format="yyyy-MM-dd"
                size="mini"
                type="date"
                placeholder="日期"
                style="width: 150px"
                :value="privateLesson.startedDateAt"
                @input="
                  value => {
                    privateLesson.startedDateAt = value;
                    setNextLessonTime();
                  }
                "
              />
              <el-time-select
                size="mini"
                :picker-options="{
                  start: '00:00',
                  step: '00:15',
                  end: '23:45'
                }"
                placeholder="時間"
                style="width: 140px"
                :value="privateLesson.startedTimeAt"
                @input="
                  value => {
                    privateLesson.startedTimeAt = value;
                    setNextLessonTime();
                  }
                "
              />
              <TimezoneSelector
                :value="privateLesson.timezone"
                @change="
                  value => {
                    privateLesson.timezone = value;
                  }
                "
                size="mini"
              />
            </el-col>
            <el-col :span="8">
              for
              <el-input-number
                size="mini"
                style="width: 80px"
                controls-position="right"
                v-model="privateLesson.durationHours"
              />
              hour(s)
              <MinutesInput
                size="mini"
                :step="isRoleAdmin() ? 5 : 15"
                :value="privateLesson.durationMinutes"
                :change="value => (privateLesson.durationMinutes = value)"
              />
              min(s)
            </el-col>
          </el-row>
          <el-alert
            v-if="alreadyHasSameDateLessonLogVisible"
            class="alreadyHasSameDateLessonLog"
            type="warning"
            show-icon
            :closable="false"
          >
            {{
              `${moment(privateLesson.startedDateAt).format(
                "MM/DD"
              )} 已經有一個的 Lesson Log，請問是否要繼續編輯它？`
            }}
            <router-link
              :to="{
                name: 'privateClassesEditLog',
                params: {
                  privateType: $route.params.privateType,
                  id: $route.params.id,
                  lessonLogId: getLessonLogIdByDate(privateLesson.startedDateAt)
                }
              }"
            >
              {{
                `點此繼續完成 ${moment(privateLesson.startedDateAt).format(
                  "MM/DD"
                )} 的 Lesson Log。`
              }}
            </router-link>
          </el-alert>
        </el-form-item>
        <el-form-item :label="`Today's ${logType}:`">
          <!-- <el-tooltip
            content="已建立帳單，無法修改"
            placement="top"
          >
            <span>
              <i class="fas fa-question-circle" />
            </span>
            <div slot="content">
              
            </div>
          </el-tooltip> -->

          <Ckeditor
            :value="privateLesson.description"
            @input="
              description => {
                privateLesson = {
                  ...privateLesson,
                  description
                };
              }
            "
          />
          <span>
            If you're not sure what to include here, please review the
            <a
              href="https://docs.google.com/document/d/1eiD9eijXFJg8VRq1_S5YC2p32M-eRraynX_4KMsCAiA/edit#heading=h.ghpi9chvdzxx"
            >
              onboarding document.
            </a>
          </span>
        </el-form-item>
        <div v-if="!isCounseling">
          <el-form-item label="Quiz:">
            <el-row>
              <el-col :span="8">
                <el-radio-group v-model="privateLesson.hasVocabTest">
                  <el-radio-button :label="1">Yes</el-radio-button>
                  <el-radio-button :label="0">No</el-radio-button>
                </el-radio-group>
              </el-col>
              <div v-show="privateLesson.hasVocabTest === 1">
                <el-col :span="2">
                  Total score:
                </el-col>
                <el-col :span="14">
                  <el-input v-model="privateLesson.totalScore" />
                </el-col>
              </div>
              <el-alert
                type="error"
                v-if="
                  privateLesson.hasVocabTest === 1 && !privateLesson.totalScore
                "
              >
                <b>Please enter Total score, or set Quiz to 'No'.</b>
              </el-alert>
            </el-row>
          </el-form-item>

          <el-form-item
            :label="hasMultipleStudents ? 'Individual notes:' : 'Notes:'"
          >
            <el-card shadow="never">
              <el-col v-if="hasMultipleStudents" :span="4">
                <el-menu :defaultActive="String(currentStudentId)">
                  <el-menu-item
                    :index="String(student.studentClassTicketId)"
                    :key="student.studentClassTicketId"
                    v-for="student in privateLesson.students"
                    @click="
                      () => {
                        changeCurrentStudent(student.studentClassTicketId);
                      }
                    "
                  >
                    {{ student.name }}
                  </el-menu-item>
                </el-menu>
              </el-col>
              <el-col class="p-2" :span="hasMultipleStudents ? 20 : 24">
                <el-row
                  class="rowGap mb-3"
                  v-show="privateLesson.hasVocabTest === 1"
                >
                  <el-col :span="24">
                    <el-input
                      :value="currentStudentLog.score"
                      @input="
                        score => {
                          setStudentLog({ score });
                        }
                      "
                    >
                      <template slot="prepend">Vocab score</template>
                      <template slot="append">
                        / {{ privateLesson.totalScore }}</template
                      >
                    </el-input>
                  </el-col>
                </el-row>
                <el-alert
                  class="mb-3"
                  type="error"
                  v-if="
                    privateLesson.hasVocabTest === 1 && !currentStudentLog.score
                  "
                >
                  <b
                    >Please enter student's vocab score, or set Quiz to 'No'.</b
                  >
                </el-alert>
                <el-row class="rowGap">
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="Notes here"
                    v-model="currentStudentLog.noteToStudent"
                    @input="
                      noteToStudent => {
                        setStudentLog({ noteToStudent });
                      }
                    "
                  />
                </el-row>
              </el-col>
            </el-card>
          </el-form-item>
        </div>
        <el-form-item label="Homework:">
          <Ckeditor
            :value="privateLesson.homework"
            @input="
              homework => {
                privateLesson = {
                  ...privateLesson,
                  homework
                };
              }
            "
          />
        </el-form-item>
        <el-form-item v-if="isCounseling" label="notes to the student:">
          <Ckeditor
            :value="currentStudentLog.noteToStudent"
            @input="
              noteToStudent => {
                setStudentLog({ noteToStudent });
              }
            "
          />
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <el-tooltip
              class="item"
              :content="
                isRoleAdmin()
                  ? 'Only admin and the teacher can see this.'
                  : isRoleTeacher()
                  ? 'Only you can see this.'
                  : ''
              "
              placement="top"
              popper-class="tooltipColor"
            >
              <i class="el-icon-lock" />
            </el-tooltip>
            Notes to self:
          </template>
          <Ckeditor
            :value="privateLesson.noteToTeacher"
            @input="
              noteToTeacher => {
                privateLesson = {
                  ...privateLesson,
                  noteToTeacher
                };
              }
            "
          />
        </el-form-item>
        <el-form-item :label="`Next ${logType}:`">
          <el-radio-group
            :value="isDecidedNextLessonLogTime"
            @input="
              newIsDecidedNextLessonLogTime =>
                (isDecidedNextLessonLogTime = newIsDecidedNextLessonLogTime)
            "
          >
            <el-radio :label="false">待定</el-radio>
            <el-radio :label="true">已安排</el-radio>
          </el-radio-group>
          <el-row v-if="isDecidedNextLessonLogTime">
            <el-col :span="12">
              <el-date-picker
                value-format="yyyy-MM-dd"
                size="mini"
                type="date"
                placeholder="日期"
                style="width: 150px"
                v-model="privateLesson.nextLessonDateAt"
              />
              <el-time-select
                size="mini"
                :picker-options="{
                  start: '00:00',
                  step: '00:15',
                  end: '23:45'
                }"
                placeholder="時間"
                style="width: 140px"
                v-model="privateLesson.nextLessonTimeAt"
              />
              <TimezoneSelector
                :value="privateLesson.nextLessonTimezone"
                @change="
                  value => {
                    privateLesson.nextLessonTimezone = value;
                  }
                "
                size="mini"
              />
            </el-col>
            <el-col :span="8">
              for
              <el-input-number
                size="mini"
                style="width: 80px"
                controls-position="right"
                v-model="privateLesson.nextLessonDurationHours"
              />
              hour(s)
              <MinutesInput
                size="mini"
                :step="isRoleAdmin() ? 5 : 15"
                :value="privateLesson.nextLessonDurationMinutes"
                :change="
                  value => (privateLesson.nextLessonDurationMinutes = value)
                "
              />
              min(s)
            </el-col>
          </el-row>
          <el-alert
            v-if="!this.alreadyScheduleSameDate()"
            class="thatWillCreateNextLessonLog"
            title="當天尚未有Lesson Log，會新增這筆 Lesson Log"
            type="warning"
            show-icon
            :closable="false"
          />
          <router-link
            v-if="this.alreadyScheduleSameDate()"
            :to="{
              name: 'privateClassesEditLog',
              params: {
                privateType: $route.params.privateType,
                id: $route.params.id,
                lessonLogId: this.nextLessonLogId
              }
            }"
          >
            發現有同一日的Lesson Log ，
            {{
              `點此編輯 ${moment(privateLesson.nextLessonDateAt).format(
                "MM/DD"
              )} 的 Lesson Log。`
            }}
          </router-link>
        </el-form-item>
        <el-form-item v-if="!isTheFutureLessonLog">
          <el-row v-if="isRoleAdmin()">
            <div :span="24">
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="privateLesson.sendToTeacher"
              >
                Email the teacher this log
              </el-checkbox>
            </div>
            <div :span="24">
              <el-checkbox
                :true-label="1"
                :false-label="0"
                :value="privateLesson.sendToStudents"
                @input="
                  value => {
                    privateLesson = {
                      ...privateLesson,
                      sendToStudents: value,
                      sendToParents: value
                    };
                  }
                "
              >
                Email the student and the student's parent this log
              </el-checkbox>
            </div>
          </el-row>
          <el-row v-else>
            <div style="line-height: 20px;">
              Whenever you create a lesson log, please make sure to send it to
              students (which we will cc to their parents), so they can stay up
              to date with the lesson’s progress.
            </div>
            <div :span="24">
              <el-checkbox
                :true-label="1"
                :false-label="0"
                :value="privateLesson.sendToTeacher"
                @input="
                  value => {
                    privateLesson = {
                      ...privateLesson,
                      sendToTeacher: value
                    };
                  }
                "
              >
                Email yourself a copy
              </el-checkbox>
            </div>
            <div :span="24">
              <el-checkbox
                :true-label="1"
                :false-label="0"
                :value="privateLesson.sendToStudents"
                @input="
                  value => {
                    privateLesson = {
                      ...privateLesson,
                      sendToStudents: value,
                      sendToParents: value
                    };
                  }
                "
              >
                Email the student(s) a copy
              </el-checkbox>
            </div>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sendMailInformationVidible = true">
            {{ $t("courses.save") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/style.scss";
import moment from "moment";
import Breadcrumb from "@/components/Breadcrumb";
import Ckeditor from "@/components/ckeditor/index.vue";
import { TimezoneSelector } from "@/components/selector";
import { InputMoney } from "@/components/input";
import IsOnlineIcon from "@/components/icon/IsOnline";
import ClassTypeDialog from "@/components/privateLesson/ClassTypeDialog";
import AttendanceStatusLight from "@/components/privateLesson/AttendanceStatusLight";
import MinutesInput from "@/components/privateLesson/MinutesInput";
import formMixin from "@/mixins/form";
import roleMixin from "@/mixins/role";
import moneyMixin from "@/mixins/money";
import privateApi from "@/apis/private";
import lessonLogApi from "@/apis/lessonLog";
import { EnumAttendanceStatuses } from "@/enums";
import { user } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `${this.isEditModel ? "Edit" : "Add"} ${this.logType} Log - ${
        this.subject
      } - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    TimezoneSelector,
    InputMoney,
    Ckeditor,
    ClassTypeDialog,
    AttendanceStatusLight,
    MinutesInput
  },
  computed: {
    userMethods() {
      return user;
    },
    moment() {
      return moment;
    },
    EnumAttendanceStatuses() {
      return EnumAttendanceStatuses;
    },
    attendanceStatusOptions() {
      return Object.keys(EnumAttendanceStatuses).map(key => ({
        label: key,
        value: EnumAttendanceStatuses[key]
      }));
    },
    hasMultipleStudents() {
      return this.privateLesson.students.length > 1;
    },
    meetingTeacher() {
      return this.$store.getters["user/getProfile"];
    },
    logType() {
      if (this.$route.params.privateType === "counseling") {
        return "Meeting";
      } else {
        if (this.$route.query.lType === "MG") {
          return "Meet and Greet";
        } else {
          return "Lesson";
        }
      }
    },
    isCounseling() {
      return this.$route.params.privateType === "counseling";
    },
    isPrivateLesson() {
      return this.$route.name.indexOf("privateClasses") !== -1;
    },
    isEditModel() {
      return this.$route.name.indexOf("Edit") !== -1;
    },
    lessonLogs() {
      if (this.originalCustomLesson) {
        let lessonLogs = [];
        const { lesson_logs_group_by_user } = this.originalCustomLesson;
        for (const userId in lesson_logs_group_by_user) {
          lessonLogs.push(...lesson_logs_group_by_user[userId]);
        }
        return lessonLogs;
      } else {
        return [];
      }
    },
    teacherMailInfo() {
      return [
        {
          teacher: this.privateLesson.teachers.find(
            teacher => this.currentMeetingTeacherId === teacher.teacherUserId
          ),
          sendMail: {
            sendToAdmin: this.privateLesson.sendToAdmin,
            sendToParents: this.privateLesson.sendToParents,
            sendToStudents: this.privateLesson.sendToStudents,
            sendToTeacher: this.privateLesson.sendToTeacher
          }
        }
      ];
    }
  },
  watch: {
    "privateLesson.nextLessonDateAt"() {},
    "privateLesson.startedDateAt"() {
      // if (this.isEditModel) return;
      this.isTheFutureLessonLog = moment(
        this.privateLesson.startedDateAt
      ).isAfter(moment());
      let needToSendMail = this.isTheFutureLessonLog ? 0 : 1;
      this.privateLesson = {
        ...this.privateLesson,
        sendToAdmin: needToSendMail,
        sendToTeacher: needToSendMail,
        sendToStudents: needToSendMail,
        sendToParents: needToSendMail
      };
      const teacherCreatedSameDateLessonLogs = this.lessonLogs
        .filter(
          ({ teaching_log: teachingLog }) =>
            teachingLog.teacher_user_id ===
            this.privateLesson.teachers[0].teacherUserId
        )
        // .filter(({ teaching_log: teachingLog }) => !teachingLog.description)
        .filter(
          ({ started_at }) =>
            moment(started_at).format("YYYYMMDD") ===
            moment(this.privateLesson.startedDateAt).format("YYYYMMDD")
        );

      this.setNextTeachingLog();

      this.alreadyHasSameDateLessonLogVisible =
        teacherCreatedSameDateLessonLogs.length > 0 &&
        !this.$route.params.lessonLogId;
    },
    isOnline() {
      this.changeIsOnline();
      this.setCurrentMeetingTeacher(
        this.currentMeetingTeacherId,
        this.originalTeachingLog
      );
      console.log(this.privateLesson);
    },
    type() {
      this.setCurrentMeetingTeacher();
    }
  },
  mixins: [formMixin, roleMixin, moneyMixin],
  data() {
    return {
      type: "LS",
      alreadyHasSameDateLessonLogVisible: false,
      showAttendanceStatusOptions: [],
      classTypeDialogVisible: false,
      sendMailInformationVidible: false,
      ifDoYouWantToContinueDialogVisible: false,
      isTheFutureLessonLog: false,
      hasNextLessonSchedule: false,
      nextLessonLogId: null,
      subject: "",
      isOnline: 0,
      currentMeetingTeacherId: null,
      currentStudentId: null,
      currentStudentLog: {
        noteToStudent: "",
        score: null
      },
      originalCustomLesson: null,
      originalTeachingLog: null,
      originalLessonLogs: [],
      isDecidedNextLessonLogTime: false,
      privateLesson: {
        name: "",
        description: "",
        nextLessonDateAt: null,
        nextLessonTimeAt: null,
        startedDateAt: moment().format("YYYY-MM-DD"),
        startedTimeAt: "08:00",
        durationHours: 2,
        durationMinutes: 0,
        nextLessonDurationHours: 1,
        nextLessonDurationMinutes: 0,
        timezone: null,
        nextLessonTimezone: null,
        homework: "",
        noteToTeacher: "",
        sendToAdmin: 1,
        sendToTeacher: 1,
        sendToStudents: 1,
        sendToParents: 1,
        hasVocabTest: 1,
        totalScore: 20,
        students: [],
        teachers: [],
        needUpdateOrderId: null
      }
    };
  },
  beforeDestroy() {
    // 不再監聽 onbeforeunload
    window.onbeforeunload = null;
  },
  async created() {
    //阻止浏览器刷新关闭
    window.onbeforeunload = () => {
      return "Your changes may not be saved. Are you sure you want to leave the page?";
    };
    //阻止路由跳转
    this.$store.commit("router/SET_EDITING", true);
    await this.setCustomLessonInfo();
    if (this.isEditModel) {
      await this.setLessonLogInfo();
      this.isOnline = this.originalTeachingLog.is_online;
    } else {
      this.setStudents();
      this.setCurrentMeetingTeacher(this.meetingTeacher.id);
      const notYetFinishedLessonLogs = this.getAfterNotYetFinishedLessonLogs();
      if (notYetFinishedLessonLogs.length > 0) {
        this.ifDoYouWantToContinueDialogVisible = true;
      }
      this.isNeedToMail = true;
      this.isOnline = this.originalCustomLesson.session_class.is_online;
      this.classTypeDialogVisible = true;
    }
    this.setNextLessonTime();
    this.filterAttendanceStatus();
    this.setNextTeachingLog();
    if (this.privateLesson.teachers[0].is_active === 1) {
      this.type = "LS";
    } else if (this.privateLesson.teachers[0].is_active === 2) {
      this.type = "MG";
    } else {
      this.type = "LS";
    }
    if (this.$route.query.lType) {
      this.type = this.$route.query.lType;
    }
  },
  methods: {
    filterAttendanceStatus(key) {
      this.value = key;
      if (key) {
        this.showAttendanceStatusOptions = this.attendanceStatusOptions.filter(
          attendanceStatus =>
            attendanceStatus.label.toUpperCase().startsWith(key.toUpperCase())
        );
      } else {
        this.showAttendanceStatusOptions = this.attendanceStatusOptions;
      }
    },
    computeDurationMinutes() {
      const MINUTE_STEP = 15;
      const durationMinutes = this.privateLesson.durationMinutes / MINUTE_STEP;
      const [multiplier, carry] = [
        Math.floor(durationMinutes),
        String(durationMinutes).split(".")[1][0] || 0
      ];
      let computedMinutes = null;
      if (carry > 5) {
        computedMinutes = MINUTE_STEP * (multiplier + 1);
      } else {
        computedMinutes = MINUTE_STEP * multiplier;
      }
      this.privateLesson.durationMinutes =
        computedMinutes >= 60 ? 0 : computedMinutes;
    },
    getAfterNotYetFinishedLessonLogs() {
      if (!this.originalCustomLesson) return [];
      const wroteTodayLessonLogs = this.lessonLogs
        .filter(
          ({ teaching_log: teachingLog }) =>
            teachingLog.teacher_user_id ===
            this.privateLesson.teachers[0]?.teacherUserId
        )
        .filter(({ teaching_log: teachingLog }) => !teachingLog.description)
        .filter(({ started_at }) => moment(started_at).isBefore(moment()));

      const removedDuplicateLessonLogs = [];
      wroteTodayLessonLogs.forEach(lessonLog => {
        if (
          removedDuplicateLessonLogs.every(
            existLogs => existLogs.teaching_log_id !== lessonLog.teaching_log_id
          )
        ) {
          removedDuplicateLessonLogs.push(lessonLog);
        }
      });

      return removedDuplicateLessonLogs;
    },
    getLessonLogIdByDate(targetDate) {
      const targetLessonLog = this.lessonLogs.find(
        ({ started_at }) => started_at && started_at.startsWith(targetDate)
      );
      if (targetLessonLog != null) {
        return targetLessonLog.id;
      }
      return;
    },
    setCurrentMeetingTeacher(teacherUserId, teachingLog) {
      const getTeacherProfile = () => {
        let teacher = null;
        if (this.isEditModel) {
          teacher = { ...teachingLog };
        } else {
          teacher = this.originalCustomLesson.session_class.session_class_teachers.find(
            ({ teacher_user_id }) => teacher_user_id === teacherUserId
          );
        }

        if (teacher === undefined && this.isRoleAdmin()) {
          teacher = this.originalCustomLesson.session_class
            .session_class_teachers[0];
        }
        return teacher;
      };

      const meetingTeacherProfile = getTeacherProfile();
      this.currentMeetingTeacherId = meetingTeacherProfile.teacher_user.id;
      this.privateLesson = {
        ...this.privateLesson,
        teachers: [
          {
            no: 1,
            is_active: meetingTeacherProfile.is_active,
            teacherUserId: meetingTeacherProfile.teacher_user.id,
            name: meetingTeacherProfile.teacher_user.name,
            salary:
              this.type === "LS"
                ? this.isOnline
                  ? Number(meetingTeacherProfile.online_salary)
                  : Number(meetingTeacherProfile.salary)
                : 0,
            salaryCurrency:
              (this.isOnline
                ? meetingTeacherProfile.online_salary_currency
                : meetingTeacherProfile.salary_currency) || "TWD"
          }
        ]
      };
    },
    setTeacher(value) {
      this.privateLesson = {
        ...this.privateLesson,
        teachers: [
          {
            ...this.privateLesson.teachers[0],
            ...value
          }
        ]
      };
    },
    changeCurrentStudent(currentStudentId) {
      this.currentStudentId = currentStudentId;
      const currentStudent = this.privateLesson.students.find(
        student => student.studentClassTicketId === this.currentStudentId
      );

      Object.keys(this.currentStudentLog).forEach(logItem => {
        this.currentStudentLog = {
          ...this.currentStudentLog,
          [logItem]: currentStudent[logItem]
        };
      });
      console.log(this.privateLesson);
    },
    setStudent(studentClassTicketId, value) {
      const targetIndex = this.privateLesson.students.findIndex(
        student => student.studentClassTicketId === studentClassTicketId
      );
      this.privateLesson.students[targetIndex] = {
        ...this.privateLesson.students[targetIndex],
        ...value
      };

      this.privateLesson = {
        ...this.privateLesson,
        students: [...this.privateLesson.students]
      };
    },
    setStudentLog(value) {
      this.currentStudentLog = {
        ...this.currentStudentLog,
        ...value
      };
      this.setStudent(this.currentStudentId, this.currentStudentLog);
    },
    async setCustomLessonInfo() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: Number(this.$route.params.id)
      });
      this.originalCustomLesson = customLesson;

      if (this.isPrivateLesson) {
        const lessonDetail = customLesson.student_class_tickets[0];
        this.subject = lessonDetail.subject;
      } else {
        this.subject = customLesson.session_class.title;
      }

      const formattedCustomLesson = {
        ...this.privateLesson,
        timezone: this.$store.getters["user/timezone"],
        nextLessonTimezone: this.$store.getters["user/timezone"]
      };
      this.privateLesson = formattedCustomLesson;
      this.currentStudentId = customLesson.student_class_tickets[0].id;
    },
    getParents(student_id) {
      let parents = [];
      if (
        student_id &&
        this.originalCustomLesson &&
        this.originalCustomLesson.student_class_tickets
      ) {
        this.originalCustomLesson.student_class_tickets.forEach(
          (student_class_ticket, index) => {
            if (student_class_ticket.student.id === student_id) {
              parents = student_class_ticket.student.parents;
            }
          }
        );
      }
      return parents;
    },
    getParentsId(parentArr, student_id) {
      let parents = [];
      if (parentArr && parentArr.length > 0) {
        parents = parentArr;
      } else {
        if (
          student_id &&
          this.originalCustomLesson &&
          this.originalCustomLesson.student_class_tickets
        ) {
          this.originalCustomLesson.student_class_tickets.forEach(
            (student_class_ticket, index) => {
              if (student_class_ticket.student.id === student_id) {
                // parents = student_class_ticket.student.parents;
                if (
                  student_class_ticket.student.parents &&
                  student_class_ticket.student.parents.length > 0
                ) {
                  student_class_ticket.student.parents.forEach(element => {
                    parents.push(element.id);
                  });
                }
              }
            }
          );
        }
      }

      return parents;
    },
    setStudents() {
      if (this.isEditModel) {
        this.privateLesson = {
          ...this.privateLesson,
          students: this.originalLessonLogs.map((lessonLog, index) => ({
            lessonLogId: lessonLog.id,
            no: index + 1,
            fee: Number(lessonLog.origin_fee),
            feeCurrency: lessonLog.origin_currency,
            attendanceStatus: lessonLog.attendance_status,
            absenceExcuse: lessonLog.absence_excuse,
            name: lessonLog.student_user.name,
            studentClassTicketId: lessonLog.student_class_ticket_id,
            noteToStudent: lessonLog.note_to_student,
            score: lessonLog.score,
            default_parents_receivers:
              lessonLog.student_class_ticket.default_parents_receivers || [],
            parents_receivers: lessonLog.parents_receivers || [],
            parents: this.getParents(lessonLog.student_user.id)
          }))
        };
      } else {
        this.privateLesson = {
          ...this.privateLesson,
          students: this.originalCustomLesson.student_class_tickets.map(
            (student_class_ticket, index) => ({
              ...this.currentStudentLog,
              no: index + 1,
              fee: this.isOnline
                ? Number(student_class_ticket.online_fee)
                : Number(student_class_ticket.fee),
              feeCurrency:
                (this.isOnline
                  ? student_class_ticket.online_fee_currency
                  : student_class_ticket.fee_currency) || "TWD",
              attendanceStatus: 1,
              absenceExcuse: "",
              name: student_class_ticket.student.name,
              studentClassTicketId: student_class_ticket.id,
              default_parents_receivers:
                student_class_ticket.default_parents_receivers || [],
              parents_receivers:
                student_class_ticket.default_parents_receivers || [],
              parents: student_class_ticket.student.parents || []
            })
          )
        };
      }
    },
    changeIsOnline() {
      if (this.isEditModel) {
        this.privateLesson = {
          ...this.privateLesson,
          students: this.originalLessonLogs.map((lessonLog, index) => ({
            lessonLogId: lessonLog.id,
            no: index + 1,
            fee: this.isOnline
              ? lessonLog.origin_is_online
                ? Number(lessonLog.origin_fee)
                : Number(lessonLog.online_fee)
              : lessonLog.origin_is_online
                ? Number(lessonLog.fee)
                : Number(lessonLog.origin_fee),
            feeCurrency:
              (this.isOnline
                ? lessonLog.origin_is_online
                  ? lessonLog.origin_currency
                  : lessonLog.online_fee_currency
                : lessonLog.origin_is_online
                  ? lessonLog.fee_currency
                  : lessonLog.origin_currency) || "TWD",
            attendanceStatus: lessonLog.attendance_status,
            absenceExcuse: lessonLog.absence_excuse,
            name: lessonLog.student_user.name,
            studentClassTicketId: lessonLog.student_class_ticket_id,
            noteToStudent: lessonLog.note_to_student,
            score: lessonLog.score,
            default_parents_receivers:
              lessonLog.student_class_ticket.default_parents_receivers || [],
            parents_receivers: this.getParentsId(
              lessonLog.parents_receivers,
              lessonLog.student_user.id
            ),
            parents: this.originalCustomLesson.student_class_tickets[index]
              ? this.originalCustomLesson.student_class_tickets[index].student
                .parents || []
              : null
          }))
        };
      } else {
        console.log(this.privateLesson);
        this.privateLesson = {
          ...this.privateLesson,
          students: this.originalCustomLesson.student_class_tickets.map(
            (student_class_ticket, index) => ({
              ...this.currentStudentLog,
              no: index + 1,
              fee: this.isOnline
                ? Number(student_class_ticket.online_fee)
                : Number(student_class_ticket.fee),
              feeCurrency:
                (this.isOnline
                  ? student_class_ticket.online_fee_currency
                  : student_class_ticket.fee_currency) || "TWD",
              attendanceStatus: 1,
              absenceExcuse: "",
              name: student_class_ticket.student.name,
              studentClassTicketId: student_class_ticket.id,
              default_parents_receivers:
                student_class_ticket.default_parents_receivers || [],
              parents_receivers:
                student_class_ticket.default_parents_receivers || [],
              parents: student_class_ticket.student.parents || []
            })
          )
        };
      }
    },
    async setLessonLogInfo() {
      const lessonLogId = this.$route.params.lessonLogId;
      const {
        lesson_logs: lessonLogs,
        teaching_log: teachingLog
      } = await lessonLogApi.fetchLessonLog(lessonLogId);

      this.originalTeachingLog = teachingLog;
      this.originalLessonLogs = lessonLogs.map(lessonLog => ({
        ...lessonLog,
        origin_fee: lessonLog.fee,
        origin_currency: lessonLog.fee_currency,
        origin_is_online: this.originalTeachingLog.is_online,
        fee: lessonLog.student_class_ticket.fee,
        fee_currency: lessonLog.student_class_ticket.fee_currency,
        online_fee: lessonLog.student_class_ticket.online_fee,
        online_fee_currency: lessonLog.student_class_ticket.online_fee_currency
      }));

      if (this.isPrivateLesson) {
        const teacher = this.originalCustomLesson.session_class.session_class_teachers.find(
          ({ teacher_user_id }) =>
            teacher_user_id === teachingLog.teacher_user.id
        );
        console.log(teacher);
        this.originalTeachingLog = {
          ...teachingLog,
          salary: this.originalTeachingLog.is_online
            ? teachingLog.salary
            : teacher.salary,
          salary_currency: this.originalTeachingLog.is_online
            ? teachingLog.salary_currency
            : teacher.salary_currency,
          online_salary: this.originalTeachingLog.is_online
            ? teachingLog.salary
            : teacher.online_salary,
          online_salary_currency: this.originalTeachingLog.is_online
            ? teachingLog.salary_currency
            : teacher.online_salary_currency
        };
      } else {
        this.originalTeachingLog = { ...teachingLog };
      }

      const [nextLessonDateAt, nextLessonTimeAt] = (
        teachingLog.next_lesson_at || " "
      ).split(" ");
      console.log(teachingLog.next_lesson_at);
      console.log(nextLessonDateAt);
      if (
        nextLessonDateAt ||
        (nextLessonTimeAt &&
          this.getLessonLogIdByDate(nextLessonDateAt) != null)
      ) {
        console.log(this.getLessonLogIdByDate(nextLessonDateAt));
        this.isDecidedNextLessonLogTime = true;
      }
      console.log(teachingLog.started_at);
      const [startedDateAt, startedTimeAt] = (
        teachingLog.started_at || " "
      ).split(" ");

      const isNeedToMail = teachingLog.has_mailed ? false : true;
      this.privateLesson = {
        ...this.privateLesson,
        description: teachingLog.description,
        nextLessonDateAt:
          !nextLessonDateAt || nextLessonDateAt === "0000-00-00"
            ? null
            : nextLessonDateAt,
        nextLessonTimeAt,
        startedDateAt,
        startedTimeAt,
        durationHours: teachingLog.duration_hours,
        durationMinutes: teachingLog.duration_minutes,
        hasVocabTest: lessonLogs[0].has_vocab_test,
        totalScore: lessonLogs[0].total_score,
        timezone: teachingLog.timezone,
        homework: teachingLog.homework,
        noteToTeacher: teachingLog.note_to_teacher,
        has_mailed: teachingLog.has_mailed,
        sendToAdmin: isNeedToMail,
        sendToTeacher: isNeedToMail,
        sendToStudents: isNeedToMail,
        sendToParents: isNeedToMail
      };
      this.setCurrentMeetingTeacher(teachingLog.teacher_user.id, teachingLog);
      this.setStudents();
      this.changeCurrentStudent(lessonLogs[0].student_class_ticket_id);
    },
    alreadyScheduleSameDate() {
      if (this.lessonLogs === 0) {
        return false;
      }
      const thisLessonLogNextDateString = `${this.privateLesson.nextLessonDateAt} ${this.privateLesson.nextLessonTimeAt}:00`;
      const lessonSchedule = this.lessonLogs.find(
        ({ started_at }) => started_at === thisLessonLogNextDateString
      );
      return Boolean(lessonSchedule);
    },
    setNextLessonTime() {
      const cleanNextLessonInformation = () => {
        this.privateLesson = {
          ...this.privateLesson,
          nextLessonDurationHours: 1,
          nextLessonDurationMinutes: 0,
          nextLessonDateAt: null,
          nextLessonTimeAt: null
        };
      };
      if (this.lessonLogs.length === 0) {
        cleanNextLessonInformation();
        return;
      }

      const thisLessonLogDate = moment(
        `${this.privateLesson.startedDateAt} ${this.privateLesson.startedTimeAt}`
      );

      const lessonSchedules = this.lessonLogs.filter(lessonLog =>
        thisLessonLogDate.isBefore(moment(lessonLog.teaching_log.started_at))
      );

      if (lessonSchedules.length > 0) {
        const { teaching_log: nextTeachingSchedule } = lessonSchedules[
          lessonSchedules.length - 1
        ];
        this.privateLesson = {
          ...this.privateLesson,
          nextLessonTimezone: nextTeachingSchedule.timezone,
          nextLessonDurationHours: nextTeachingSchedule.duration_hours,
          nextLessonDurationMinutes: nextTeachingSchedule.duration_minutes,
          nextLessonDateAt: nextTeachingSchedule.started_at.split(" ")[0],
          nextLessonTimeAt: nextTeachingSchedule.started_at
            .split(" ")[1]
            .slice(0, 5)
        };
      } else {
        cleanNextLessonInformation();
      }
    },
    backToTheDetail() {
      this.$store.commit("router/SET_EDITING", false);
      this.$router.push({
        name: "ClassLessonLogs",
        params: {
          classId: this.$route.params.id
        }
      });
    },
    async updateLessonLog(postLessonLog) {
      let response = null;
      if (this.isPrivateLesson) {
        response = await lessonLogApi.updateLessonLog(
          this.$route.params.lessonLogId,
          postLessonLog
        );
      } else {
        await lessonLogApi.updateGroupLessonLog(
          this.$route.params.lessonLogId,
          postLessonLog
        );
      }
      return response;
    },
    async createLessonLog(postLessonLog) {
      if (this.isPrivateLesson) {
        await lessonLogApi.createLog(postLessonLog);
      } else {
        await lessonLogApi.createGroupLessonLog(postLessonLog);
      }
    },
    async onSubmit() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }

      const postLessonLog = {
        type: this.type,
        class_id: Number(this.$route.params.id),
        is_online: this.isOnline,
        teacher_user_id: this.privateLesson.teachers[0].teacherUserId,
        salary: this.privateLesson.teachers[0].salary,
        salary_currency: this.privateLesson.teachers[0].salaryCurrency,
        started_at: `${moment(this.privateLesson.startedDateAt).format(
          "YYYY-MM-DD"
        )} ${this.privateLesson.startedTimeAt}:00`,
        duration_hours: this.privateLesson.durationHours,
        duration_minutes: this.privateLesson.durationMinutes,
        timezone: this.privateLesson.timezone,
        description: this.privateLesson.description,
        homework: this.privateLesson.homework,
        note_to_teacher: this.privateLesson.noteToTeacher,
        next_lesson_at: this.isDecidedNextLessonLogTime
          ? `${moment(this.privateLesson.nextLessonDateAt).format(
            "YYYY-MM-DD"
          )} ${this.privateLesson.nextLessonTimeAt}:00`
          : "0000-00-00",
        has_mailed:
          this.privateLesson.has_mailed ||
          this.privateLesson.sendToAdmin ||
          this.privateLesson.sendToTeacher ||
          this.privateLesson.sendToStudents ||
          this.privateLesson.sendToParents,
        mail_to_admin:
          this.privateLesson.sendToAdmin || (this.isRoleAdmin() ? 0 : 1),
        mail_to_teacher: this.privateLesson.sendToTeacher,
        mail_to_student: this.privateLesson.sendToStudents,
        mail_to_parents: this.privateLesson.sendToStudents,
        students: this.privateLesson.students.map(student => ({
          lesson_log_id: student.lessonLogId,
          student_class_ticket_id: student.studentClassTicketId,
          attendance_status: student.attendanceStatus,
          absence_excuse: student.absenceExcuse,
          note_to_student: student.noteToStudent,
          has_vocab_test: this.privateLesson.hasVocabTest,
          total_score: this.privateLesson.totalScore,
          score: student.score,
          fee: student.fee,
          fee_currency: student.feeCurrency,
          fee_unit: 3,
          parents_receivers: student.parents_receivers
        }))
      };

      if (this.isEditModel) {
        await this.updateLessonLog(postLessonLog);
        // 老师更新线上或者线下发送邮件通知管理员
        if (
          this.originalTeachingLog.is_online !== this.isOnline &&
          !this.isRoleAdmin()
        ) {
          // alert(1);
        }
        const response = await this.updateLessonLog(postLessonLog);
        if (response) {
          if (response.paid_order_id != null) {
            let message =
              "此堂課已經有付款過的帳單，請先刪除帳單才可編輯學費，帳單ID: <a target='_blank' href='/bill/" +
              response.paid_order_id +
              "'>" +
              response.paid_order_id +
              "</a>";
            this.$message({
              showClose: true,
              message: message,
              type: "error",
              dangerouslyUseHTMLString: true
            });
            return false;
          }
          this.needUpdateOrderId = response.need_update_order_id;
        }
      } else {
        await this.createLessonLog(postLessonLog);
      }
      if (this.isDecidedNextLessonLogTime && !this.alreadyScheduleSameDate()) {
        console.log(postLessonLog);
        this.createScheduleLog(postLessonLog);
      }
      let message = this.isEditModel
        ? "update_success"
        : "lesson_log_created_successfully";
      if (this.needUpdateOrderId != null) {
        message =
          "你的更新連動一筆帳單金額，請再確認，帳單ID: <a target='_blank' href='/bill/" +
          this.needUpdateOrderId +
          "'>" +
          this.needUpdateOrderId +
          "</a>";
        this.$message({
          showClose: true,
          message: message,
          type: "warning",
          dangerouslyUseHTMLString: true
        });
      } else {
        message = this.$t(`message.${message}`);
        this.$message({
          showClose: true,
          message: message,
          type: "success"
        });
      }
      this.backToTheDetail();
    },
    async createScheduleLog(lessonLog) {
      const schedule = {
        ...lessonLog,
        // salary: this.originalCustomLesson.session_class.salary,
        // salary_currency: this.originalCustomLesson.session_class
        //   .salary_currency,
        type: "LS",
        has_mailed: 0,
        mail_to_admin: 0,
        mail_to_teacher: 0,
        mail_to_student: 0,
        mail_to_parents: 0,
        started_at: `${moment(this.privateLesson.nextLessonDateAt).format(
          "YYYY-MM-DD"
        )} ${this.privateLesson.nextLessonTimeAt}:00`,
        duration_hours: this.privateLesson.nextLessonDurationHours,
        duration_minutes: this.privateLesson.nextLessonDurationMinutes,
        description: "",
        homework: "",
        note_to_teacher: "",
        next_lesson_at: null,
        timezone: this.privateLesson.nextLessonTimezone,
        students: this.originalCustomLesson.student_class_tickets.map(
          (student, index) => ({
            lesson_log_id: this.$route.params.lessonLogId,
            student_class_ticket_id: student.id,
            fee: Number(lessonLog.students[index].fee),
            fee_currency: lessonLog.students[index].fee_currency,
            fee_unit: 3,
            attendance_status: 1,
            has_vocab_test: 0,
            parents_receivers: student.default_parents_receivers
          })
        )
      };
      await this.createLessonLog(schedule);
    },
    async setNextTeachingLog() {
      const nextTeachingLog = await lessonLogApi.fetchNextTeachingLog({
        session_class_id: this.$route.params.id,
        started_at: `${moment(this.privateLesson.startedDateAt).format(
          "YYYY-MM-DD"
        )} ${this.privateLesson.startedTimeAt}:00`,
        timezone: this.privateLesson.nextLessonTimezone,
        teacher_user_id: this.privateLesson.teachers[0].teacherUserId
      });
      console.log(nextTeachingLog.next_teaching_log);

      if (nextTeachingLog.next_teaching_log != null) {
        this.isDecidedNextLessonLogTime = true;
        this.nextLessonLogId = nextTeachingLog.next_teaching_log.id;
      } else {
        this.isDecidedNextLessonLogTime = false;
      }
    }
  }
};
</script>

<style scoped>
.alreadyHasSameDateLessonLog {
  margin-top: 20px;
}

::v-deep .alreadyHasSameDateLessonLog > div > p {
  font-size: 16px;
}
.thatWillCreateNextLessonLog {
  margin-top: 20px;
}
</style>
